<template>
    <Guest :title="t('page_title')" text_center>
        <PageWithButtonBelow>
            <template v-slot:default>
                <div class="text-white">
                    <p v-if="user.email" class="text-center">{{ t('send_email_to', { email: user.email }) }}</p>
                    <p v-else class="text-center">{{ t('send_email') }}</p>
                    <p class="text-center mt-2 mb-6">{{ t('check_spam') }}</p>
                </div>
            </template>

            <template v-slot:button>
                <span class="block w-full rounded-md shadow-sm">
                    <button @click="resend_verification_email" type="submit" class="button w-full">
                        {{ t("resend_email") }}
                    </button>
                </span>
            </template>
        </PageWithButtonBelow>
    </Guest>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import Guest from "@/wrappers/Guest";
import { resendVerificationEmail } from "@/helpers/api/RegistrationHelper";
import messages from "@/helpers/storage/MessageHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "EmailVerificationResend",
    components: {
        Guest,
        PageWithButtonBelow,
    },
    setup() {
        return useI18n({
            viewPrefix: "EmailVerificationResend"
        });
    },
    watch: {
        user: function (user) {
            // somewhere in the application, the user was changed. Check if verified is true, and redirect
            if (user.verified === 1) {
                this.$router.push({ name: 'dashboard' })
            }
        }
    },
    computed: {
        user: function(){
            return this.$store.getters['getUser'];
        },
    },
    methods: {
        resend_verification_email(){
            setLoading(true);

            resendVerificationEmail()
                .then(() => messages.success(this.t('messages.email_send')))
                .catch((error) => messages.error(this.t('messages.error', {error: error.message})))
                .finally(() => setLoading(false));
        }
    },
}
</script>